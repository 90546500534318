<template>
	<div class="team-preview">
		<div class="deals-wrapper card-shadow">
			<div class="team-fields pb-2">
				<div class="team-grp">
					<v-layout class="px-4 py-2 border-bottom">
						<div class="my-auto font-level-3-bold fw-600">Deals Status</div>
						<v-spacer></v-spacer>
						<div class="d-flex">
							<v-select
								class="cm-menu-select elevation-0 red--text"
								v-model="status"
								:items="StatusList"
								hide-details
								dense
								solo
							>
								<template v-slot:selection="{ item }">
									<span class="blue--text font-level-3-bold">{{ item }}</span>
								</template>
							</v-select>
						</div>
					</v-layout>
					<div class="v-data-table bt-table theme--light">
						<div class="v-data-table__wrapper">
							<table width="100">
								<thead>
									<tr>
										<th>Name</th>
										<th width="200">Last Contected</th>
										<th>Sales Representative</th>
										<th width="150">Status</th>
										<th width="150">Deals Status</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<p class="fw-500">Absternet LLC</p>
										</td>
										<td>
											<p class="fw-500">Sep 20, 2021</p>
										</td>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar size="40">
													<img src="https://cdn.vuetifyjs.com/images/lists/2.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Donald Risher</h4>
												</div>
											</div>
										</td>
										<td>
											<v-chip label small color="success" outlined> Deal Won </v-chip>
										</td>
										<td>
											<p class="fw-500">$100.1K</p>
										</td>
									</tr>
									<tr>
										<td>
											<p class="fw-500">Absternet LLC</p>
										</td>
										<td>
											<p class="fw-500">Sep 20, 2021</p>
										</td>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar size="40">
													<img src="https://cdn.vuetifyjs.com/images/lists/2.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Donald Risher</h4>
												</div>
											</div>
										</td>
										<td>
											<v-chip label small color="success" outlined> Deal Won </v-chip>
										</td>
										<td>
											<p class="fw-500">$100.1K</p>
										</td>
									</tr>
									<tr>
										<td>
											<p class="fw-500">Absternet LLC</p>
										</td>
										<td>
											<p class="fw-500">Sep 20, 2021</p>
										</td>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar size="40">
													<img src="https://cdn.vuetifyjs.com/images/lists/2.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Donald Risher</h4>
												</div>
											</div>
										</td>
										<td>
											<v-chip label small color="success" outlined> Deal Won </v-chip>
										</td>
										<td>
											<p class="fw-500">$100.1K</p>
										</td>
									</tr>
									<tr>
										<td>
											<p class="fw-500">Absternet LLC</p>
										</td>
										<td>
											<p class="fw-500">Sep 20, 2021</p>
										</td>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar size="40">
													<img src="https://cdn.vuetifyjs.com/images/lists/2.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Donald Risher</h4>
												</div>
											</div>
										</td>
										<td>
											<v-chip label small color="success" outlined> Deal Won </v-chip>
										</td>
										<td>
											<p class="fw-500">$100.1K</p>
										</td>
									</tr>
									<tr>
										<td>
											<p class="fw-500">Absternet LLC</p>
										</td>
										<td>
											<p class="fw-500">Sep 20, 2021</p>
										</td>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar size="40">
													<img src="https://cdn.vuetifyjs.com/images/lists/2.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Donald Risher</h4>
												</div>
											</div>
										</td>
										<td>
											<v-chip label small color="success" outlined> Deal Won </v-chip>
										</td>
										<td>
											<p class="fw-500">$100.1K</p>
										</td>
									</tr>
									<tr>
										<td>
											<p class="fw-500">Absternet LLC</p>
										</td>
										<td>
											<p class="fw-500">Sep 20, 2021</p>
										</td>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar size="40">
													<img src="https://cdn.vuetifyjs.com/images/lists/2.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Donald Risher</h4>
												</div>
											</div>
										</td>
										<td>
											<v-chip label small color="success" outlined> Deal Won </v-chip>
										</td>
										<td>
											<p class="fw-500">$100.1K</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
export default {
	name: "team-member",
	title: "Listing Team",
	mixins: [ListingMixin],
	data() {
		return {
			status: "All Time",
			StatusList: ["All Time", "This Week", "Last Week", "This Month", "Last Month"],
		};
	},
};
</script>

<style scoped>
.v-progress-circular {
	margin: 1rem;
}
</style>
